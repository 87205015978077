import { Center, Circle, Container, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { getHorseGradientStr, getHorseTextColor } from "../../lib/utils";
import { darkTheme_colors, lightTheme_colors } from "../../theme";

export default function PopList({ PopListdata }: { PopListdata: any }) {
    const [popList, setpopList] = useState<any>()

    useEffect(() => {
        if (PopListdata === undefined) {
            return
        }

        let imsi_popdatalist = []
        for (let i = 0; i < PopListdata.length; i++) {
            imsi_popdatalist[i] = {
                "created_at": PopListdata[i].created_at.split("T")[1].substring(0, 5),
                "ranking_list": JSON.parse(PopListdata[i].data_str),
            }
        }

        setpopList(imsi_popdatalist)

    }, [PopListdata])


    //테마 관련코드
    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const mainBgColor = useColorModeValue(lightTheme_colors['mainBgColor'], darkTheme_colors['mainBgColor']);

    if (PopListdata === undefined || PopListdata.length === 0) {
        return (
            <Flex h={"100%"} justifyContent={"center"} alignItems={"center"}>
                <Text>로딩중입니다.</Text>
            </Flex>
        )
    }
    return (
        <Flex h={"100%"} w={"100%"} overflowX={"scroll"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} bg={mainBgColor}>
            {
                popList && popList.map((data: any, index: number) => (
                    <Container
                        key={index}
                        w={"30%"}
                        h={"98%"}
                        margin={"0.1rem"}
                        borderRadius={"0.3rem"}
                        padding={"0"}
                    >
                        <Center>
                            {data.created_at}
                        </Center>
                        {data.ranking_list.map((data: any, index: number) => (
                            <HStack
                                key={index}
                                w={"100%"}
                                h={"1.5rem"}
                                margin={"0.1rem"}
                                borderRadius={"0.3rem"}
                                bg={bgColor}
                                padding={"0.1rem"}
                            >
                                <Circle
                                    key={index}
                                    size='1.5rem' fontSize={"1.1rem"} boxShadow='dark-lg' color={getHorseTextColor(data[0])}
                                    bgGradient={getHorseGradientStr(data[0])}
                                    marginRight={"-1"}
                                >
                                    {data[0]}
                                </Circle>
                                <Text fontSize={"0.7rem"} marginRight={"-1"}>{data[1]}</Text>
                                <Text fontSize={"0.7rem"}>{data[2].split("-")[0]}</Text>
                                <Text marginX={"-1.5"} fontSize={"0.7rem"}>-</Text>
                                <Text fontSize={"0.7rem"}>{data[2].split("-")[1]}</Text>
                            </HStack>
                        ))}
                    </Container>
                ))
            }


        </Flex>

    )
}