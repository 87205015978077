import { createBrowserRouter } from "react-router-dom";
import Root from "./components/Root";
import BuyRace from "./contents/BuyRace";
import DepasitCoin from "./contents/DepasitCoin";
import Home from "./contents/Home/Home";
import LoginFirst from "./contents/LoginFirst";
import RaceInfo from "./contents/RaceInfo/RaceInfo";


const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        // element: <TestRoot />,

        children: [
            {
                path: "",
                element: <Home />,
            },
            {
                path: "loginfirst",
                element: <LoginFirst />,
            },
            {
                path: "raceinfo/:racePk",
                element: <RaceInfo />,
            },
            {
                path: "depasitcoin",
                element: <DepasitCoin />,
            },
            {
                path: "buyrace/:racePk",
                element: <BuyRace />,
            },
        ]
    }
]);
export default router;