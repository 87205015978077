import { extendTheme, type ThemeConfig } from '@chakra-ui/react'

const config: ThemeConfig = {
    initialColorMode: "system",
    useSystemColorMode: false,
};

const theme = extendTheme({ config });

export default theme;


export const lightTheme_colors = {
    bgColor: "#cff8dc",
    mainBgColor: "#38A169",
    mainFontColor: "#02012c",
    frontNameColor: "#5151d6",
    btnBgColor: "#7cc9a0",
    listActivateColor: "#46ca35",
    listDeactivateColor: "#bfeea0bd",


    btnSelectColor: "#e06565",
    deactivationColor: "#5c1a05",

    inputBgColor: "#e8f6ff",


    bgHoverColor: "#dcdfe0",
    receSelectColor: "#d1c407",
    borderColor: "#eefafc",
}

export const darkTheme_colors = {
    bgColor: "#213441",
    mainBgColor: "#5b737c",
    mainFontColor: "#ced4d1",
    frontNameColor: "#7878c5",
    btnBgColor: "#5d7681",

    listActivateColor: "#83a9b9",

    listDeactivateColor: "#2f3e44",

    btnSelectColor: "#701414",
    deactivationColor: "#360303",

    bgHoverColor: "#4a5052",
    inputBgColor: "#1f272b",
    receSelectColor: "#928905",
    borderColor: "#121216",

}