import { axiosInstance } from "./base";
import Cookies from "js-cookie";

//dan_popular
export const getDanPopular = (queryKey: any) => (
    axiosInstance.get(
        `danpopular/${queryKey}`,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
                "X-CSRFToken": Cookies.get("csrftoken") || "",
            },
        }
    ).then((response) => response.data)
)
