import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

export default function Root() {
    return (
        <>
            <Box width={"100%"} height={"8vh"} >
                <Header />
            </Box>
            <Box width={"100%"} height={"85vh"}>
                <Outlet />
            </Box>
            <Box width={"100%"} height={"7vh"} >
                <Footer />
            </Box>
        </>
    )
}