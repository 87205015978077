export const formatDate = (date: Date) =>
    `${date.getFullYear()}-${date.getMonth() + 1}-${(date.getDate().toString()).length === 1 ? "0" : ""}${date.getDate()}`;


export function getRegin(type: string) {
    return {
        seoul: "서울 ",
        busan: "부산 ",
        jejuoo: "제주 ",
        obihiroba: "오히로바",
        monbetsu: "몬벳츠",
        morioka: "모리오카",
        mizusawa: "미즈사와",
        urawa: "우라와",
        hunebashi: "후네바시",
        oii: "오이",
        kawasaki: "카와사키",
        kanesawa: "카네사와",
        kasamatsu: "카사마츠",
        nagoya: "나고야",
        sonoda: "소노다",
        hemeji: "헤메지",
        kochi: "고치",
        saga: "사가",
        tokyo: "도쿄",
        kyoto: "교토",
        kokura: "코쿠라",
        nakayama: "나카야마",
        hansin: "한신",
        jungkyo: "중교",
        hukushima: "후쿠시마",
        niigata: "니가타",
        hakodate: "하코다테",
        sapooro: "삿포로",

    }[type];
}


export function getHorseGradientStr(horseNo: string) {
    return {
        1: 'linear(to-br, white 0%, white 100% )',
        2: 'linear(to-br,yellow 0%,  yellow 100%  )',
        3: 'linear(to-br,red 0%, red 100% )',
        4: 'linear(to-br,black 0%, black 100% )',
        5: 'linear(to-br,blue 0%, blue 100% )',
        6: 'linear(to-br,green 0%, green 100% )',
        7: 'linear(to-br,sienna 0%, sienna 100% )',
        8: 'linear(to-br,pink 0%, pink 100% )',
        9: 'linear(to-br,violet 0%, violet 100% )',
        10: 'linear(to-br, skyblue 0% ,skyblue 100% )',
        11: 'linear(to-br, skyblue 30%, white 35%,white 65% ,skyblue 70% )',
        12: 'linear(to-br, skyblue 30%, yellow 35%,yellow 65% ,skyblue 70% )',
        13: 'linear(to-br, skyblue 30%, red 35%,red 65% ,skyblue 70% )',
        14: 'linear(to-br, skyblue 30%, black 35%,black 65% ,skyblue 70% )',
        15: 'linear(to-br, white 30%, blue 35%,blue 65% ,white 70% )',
        16: 'linear(to-br, white 30%, green 35%,green 65% ,white 70% )',
        17: 'linear(to-br, white 30%, sienna 35%,sienna 65% ,white 70% )',
        18: 'linear(to-br, white 30%, pink 35%,pink 65% ,white 70% )',
    }[horseNo];
}
export function getHorseTextColor(horseNo: string) {
    return {
        1: 'black',
        2: 'black',
        3: 'black',
        4: 'white',
        5: 'white',
        6: 'black',
        7: 'white',
        8: 'black',
        9: 'white',
        10: 'white',
        11: 'black',
        12: 'black',
        13: 'white',
        14: 'white',
        15: 'white',
        16: 'black',
        17: 'white',
        18: 'black',
    }[horseNo];
}