import { Grid, GridItem, useColorModeValue } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import useLoginOnlyPage from "../../components/LoginProtectedPage";
import { useEffect, useState } from 'react';
import RaceTime from "../Home/RaceTimeCounter";

import useInterval from "../../actions/customHook";
import { Idata } from "./InfoList";
import { useRecoilValue } from "recoil";

import InfoTopList from "./InfoTopList";
import PopList from "./popList";
import { IRacelist } from "../../api/racelist";
import { getDanPopular } from "../../api/dan_popular";
import { raceinfoIsPauseAtom } from "../../atoms/race";
import { darkTheme_colors, lightTheme_colors } from "../../theme";
import ShowViedo from "./ShowViedo";


/*
    - 임시로 다시 만든다!!..(테마도 적용안되어있다.. ㅠㅠ)
    - 여기서 레이스 정보를 전부 가져와서 처리하자.

    - dan pupulars 데이터 가져온다.
    - b-rangking 데이터 가져온다.
*/
export default function RaceInfo() {
    //페이지 권한, 파라미터.
    useLoginOnlyPage()

    // 레이스 pk
    const { racePk } = useParams();  ///raceinfo/241
    const [race, setrace] = useState<IRacelist>()

    // youtube id
    const [youtubeId, setYoutubeId] = useState<string>("")

    // info 데이터 가져온다.
    const [infoTopData, setInfoTopData] = useState<Idata[]>()
    const [popListData, setpopListData] = useState<[]>()
    const dnaPopularsmutation = useMutation(getDanPopular, {
        onSuccess: (data: any) => {
            setrace(data.race)
            setInfoTopData(data.b_ranking)
            setpopListData(data.dan_popular)
            setYoutubeId(data.race.youtube_url?.split("v=")[1])
        },
        onError: (data: any) => {
            console.log("raceinfo dnaPopularsmutation error(detail):", data.response.data.detail)
        }
    });

    useEffect(() => {
        dnaPopularsmutation.mutate(racePk)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // 데이터 2초에 한번 가져옴.. 일시정지!
    const raceInfoIsPause = useRecoilValue(raceinfoIsPauseAtom)
    useInterval(() => {
        if (!raceInfoIsPause) {
            dnaPopularsmutation.mutate(racePk)
        }
    }, 2000);


    //테마 관련코드
    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const mainBgColor = useColorModeValue(lightTheme_colors['mainBgColor'], darkTheme_colors['mainBgColor']);
    return (
        <Grid padding={"0.2rem"}
            h='100%'
            templateRows='repeat(15, 1fr)'
            templateColumns='repeat(2, 1fr)'
            gap={1}
        >
            {
                race?.result === "" ? (
                    <>
                        <GridItem borderRadius={"2xl"} rowSpan={1} colSpan={2} bg={mainBgColor} >
                            <RaceTime racedata={race} />
                        </GridItem>
                        <GridItem rowSpan={4} colSpan={2} bg={bgColor}>
                            <ShowViedo youtubeId={youtubeId}></ShowViedo>
                        </GridItem>

                        <GridItem rowSpan={6} colSpan={2} bg={bgColor}>
                            <PopList PopListdata={popListData}></PopList>
                        </GridItem>

                        <GridItem rowSpan={5} colSpan={2} bg={bgColor}>
                            <InfoTopList TopListdata={infoTopData}></InfoTopList>
                        </GridItem>
                    </>
                ) : (
                    <>
                        <GridItem borderRadius={"2xl"} rowSpan={1} colSpan={2} bg={mainBgColor} >
                            <RaceTime racedata={race} />
                        </GridItem>

                        <GridItem rowSpan={7} colSpan={2} bg={bgColor}>
                            <PopList PopListdata={popListData}></PopList>
                        </GridItem>

                        <GridItem rowSpan={9} colSpan={2} bg={bgColor}>
                            <InfoTopList TopListdata={infoTopData}></InfoTopList>
                        </GridItem>
                    </>

                )
            }
        </Grid>
    )
}