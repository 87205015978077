import { Avatar, Box, Center, Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, useColorMode, useColorModeValue, useDisclosure, useToast } from "@chakra-ui/react";
import { FaHorseHead, FaMoon, FaSun } from 'react-icons/fa';
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from "recoil";
import LoginModal from "./LoginModal";
import { useMutation } from "@tanstack/react-query";
import useInterval from '../actions/customHook';
import SignupModal from "./SignupModal";
import CSRFToken from "./CSRFToken";
import { IUserInfo, getUserInfo, putUserReLoadDone, userLogout } from "../api/user";
import { IseverDetailReturn } from "../api/base";
import { IsLoginAtom, UserCoinsAtom, UserInfoAtom, UserInfoDefaultData } from "../atoms/user";
import { getUserCoins } from "../api/coin";
import { darkTheme_colors, lightTheme_colors } from "../theme";

export default function Header() {
    const toast = useToast();
    /* 유저정보 연속체크
        강제로그아웃시 메세지 송출, 
        유저 로그아웃시 메세지 송출 (이떄 강제로그아웃 메세지는 나오면 안된다.)
            (userlogout 변수에 유저가 로그아웃하면 true , 문제없이 유저정보를 가져오면 false 값으로 컨트롤)
    */
    //로그인/ 정보처리
    const [isLogin, setIsLogin] = useRecoilState<Boolean>(IsLoginAtom);
    const [userinfo, setuserinfo] = useRecoilState<IUserInfo>(UserInfoAtom);
    const [userlogout, setuserlogout] = useState<Boolean>(false);

    useEffect(() => {
        // console.log("token: ",localStorage.getItem("token"))
        if (localStorage.getItem("token") === null) {
            setIsLogin(false)
            setuserlogout(true)
        } else {
            firstuserinfomutation.mutate()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useInterval(() => {
        if (isLogin) { intervaluserinfomutation.mutate() }
        // console.log("userlogout :",userlogout)
    }, 3000);

    const firstuserinfomutation = useMutation(getUserInfo, {
        onSuccess: (data: IUserInfo) => {
            setIsLogin(true)
            setuserinfo(data)
            userCoinsmutation.mutate()
        },
        onError: (data: IseverDetailReturn) => {
            // console.log("header firstuserinfomutation error(detail):", data.response.data.detail)    
            setIsLogin(false)
            setuserlogout(true)
        }
    });
    const intervaluserinfomutation = useMutation(getUserInfo, {
        onSuccess: (data: IUserInfo) => {
            setIsLogin(true)
            setuserlogout(false)
            setuserinfo(data)
            // console.log(data.reload)     
            if (data.reload) {
                if (data.reload === "deposit") {
                    userCoinsmutation.mutate()
                    userReloadDonemutation.mutate()
                } else if ((data.reload === "coin")) {
                    userCoinsmutation.mutate()
                    userReloadDonemutation.mutate()
                }
            }

        },
        onError: (data: IseverDetailReturn) => {
            // console.log("header severDetailReturn error(detail):", data.response.data.detail)  
            setIsLogin(false)
            setuserlogout(true)
            setuserinfo(UserInfoDefaultData)
            localStorage.removeItem("token")
            toast({
                position: 'top',
                title: "로그아웃 되었습니다. ",
                description: "다른곳에서 로그인하였거나. 서버에서 거절당했습니다.",
                status: "success",
                isClosable: true,
            });

        }
    });
    const userReloadDonemutation = useMutation(putUserReLoadDone, {
        onSuccess: (data: IUserInfo) => {
            firstuserinfomutation.mutate()
        }
    });

    //로그아웃
    const onLogOut = async () => {
        userlogoutmutation.mutate();
    };
    const userlogoutmutation = useMutation(userLogout, {
        onSuccess: (data) => {
            setuserlogout(true)
            setIsLogin(false)
            setuserinfo(UserInfoDefaultData)
            localStorage.removeItem("token")
            toast({
                position: 'top',
                title: "로그아웃 되었습니다. ",
                description: "또 방문해주세요.",
                status: "success",
                isClosable: true,
            });
        },
        onError: (data: IseverDetailReturn) => {
            // console.log("header userlogoutmutation error(detail):", data.response.data.detail)  
            toast({
                position: 'top',
                title: "로그아웃에 실패했습니다. ",
                description: "관리자에게 문의해 주세요",
                status: "error",
                isClosable: true,
            });
        }
    });

    //UserCoin
    // const [usercoins, setusercoins] = useRecoilState(UserCoinsAtom);
    const setusercoins = useSetRecoilState(UserCoinsAtom)
    useEffect(() => {
        if (!userlogout && isLogin) {
            userCoinsmutation.mutate()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userlogout]);

    const userCoinsmutation = useMutation(getUserCoins, {
        onSuccess: (data) => {
            setusercoins(data)
        },
        onError: (data: IseverDetailReturn) => {
            // console.log("header userCoinsmutation error(detail):", data.response.data.detail) 
        }
    });

    // 모달창 호출
    const {
        isOpen: isLoginOpen,
        onClose: onLoginClose,
        onOpen: onLoginOpen,
    } = useDisclosure();
    const {
        isOpen: isSignupOpen,
        onClose: onSignupClose,
        onOpen: onSignupOpen,
    } = useDisclosure();

    // // 동적 화면 사이즈 추출하여 유저아이디 표시

    const [idShowConf, setIdShowConf] = useState({ 'top': 0, 'left': 0, 'fontSize': "3rem" })

    useInterval(() => {
        let InnerWidth = window.innerWidth;
        let InnerHeight = window.innerHeight;
        let fontsize = "4rem"
        let divaa = document.getElementById('name');
        let offWidth = divaa ? divaa.offsetWidth : 0

        if (offWidth > InnerWidth / 2) {
            fontsize = "2rem"
        }
        if (InnerWidth < 1000) {
            fontsize = "4rem"
        } else if (InnerWidth < 1500) {
            fontsize = "7rem"
        } else {
            fontsize = "9rem"
        }

        const new_idShowConf = {
            'top': InnerHeight / 2,
            'left': InnerWidth / 2 - (offWidth / 2),
            'fontSize': fontsize,
        }

        if (JSON.stringify(idShowConf) !== JSON.stringify(new_idShowConf)) {
            setIdShowConf(new_idShowConf)
        }

    }, 1000);

    // 현제페이지가 리스트 페이지인지 확인(로그인후 루트가 리스트페이지임을 이용)
    const location = useLocation();
    const [isRaceListPage, setIsRaceListPage] = useState<boolean>(false)
    useEffect(() => {
        location.pathname === "/" ? setIsRaceListPage(true) : setIsRaceListPage(false)
    }, [location])

    // 테마 관련 코드
    const { toggleColorMode } = useColorMode();
    const Icon = useColorModeValue(FaMoon, FaSun);
    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const frontNameColor = useColorModeValue(lightTheme_colors['frontNameColor'], darkTheme_colors['frontNameColor']);

    return (
        <Flex height={"100%"} justifyContent={"space-between"} bgColor={bgColor}>
            <Center flex={"1"}>
                <Link to={"/"} >
                    <FaHorseHead size={"2rem"} />
                </Link>
            </Center>
            <Center flex={"3"} fontSize={"1.5rem"} >J_RACEINFO</Center>
            <Flex flex={"1"} justifyContent={"space-around"} alignItems="center">
                <IconButton
                    onClick={toggleColorMode}
                    variant={"ghost"}
                    aria-label="Toggle dark mode"
                    icon={<Icon />}
                    marginRight={"0.5rem"}
                />
                {isLogin ? (
                    <Menu>
                        <MenuButton>
                            <Avatar name={userinfo?.username} size={"sm"} />
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={onLogOut}>로그아웃</MenuItem>

                        </MenuList>
                        {/* <Button onClick={onLogOut} size={"sm"} variant='outline' marginRight={"3px"} bgColor={btnBgColor}>로그아웃</Button> */}
                    </Menu>
                ) : (
                    <Menu>
                        <MenuButton>
                            <Avatar name={userinfo?.username} size={"sm"} />
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={onLoginOpen}>로그인</MenuItem>
                            <MenuItem onClick={onSignupOpen}>회원가입</MenuItem>
                        </MenuList>
                    </Menu>
                )}
            </Flex>
            <CSRFToken />
            <LoginModal isOpen={isLoginOpen} onClose={onLoginClose} />
            <SignupModal isOpen={isSignupOpen} onClose={onSignupClose} />

            {isRaceListPage === false && (
                <Box id={"name"} color={frontNameColor} pos={"absolute"} top={idShowConf.top} left={idShowConf.left} fontSize={idShowConf.fontSize} opacity={0.5} >
                    {userinfo.username}
                </Box>
            )}

        </Flex>
    );
}