import { Button, Center, Input, InputGroup, InputLeftElement, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, useColorModeValue, useToast } from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useSetRecoilState } from "recoil";
import { IsLoginAtom } from "../atoms/user";
import { IUserLogin, IuserLoginReturn, userLogIn } from "../api/user";
import { IseverDetailReturn } from "../api/base";
import { darkTheme_colors, lightTheme_colors } from "../theme";

interface ILoginModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export default function LoginModal({ isOpen, onClose }: ILoginModalProps) {
    const setIsLogin = useSetRecoilState(IsLoginAtom)
    // const [PreventOfDuplicateExecution , setPreventOfDuplicateExecution] = useState(false)
    // const setDupliactePrevant = () => {
    //     setPreventOfDuplicateExecution(true)
    //     setTimeout( ()=>{setPreventOfDuplicateExecution(false) } , 2000)               
    // }   

    const toast = useToast();
    const { register, handleSubmit, formState, reset } = useForm<IUserLogin>();
    const [passwordshow, setPasswordshow] = useState(false)
    const passwordshowHandleClick = () => setPasswordshow(!passwordshow)
    const [serverdetailmessage, setserverdetailmessage] = useState("")

    const onSubmit = ({ username, password }: IUserLogin) => {
        // setDupliactePrevant()
        mutation.mutate({ username, password })
    }

    const mutation = useMutation(userLogIn, {
        onSuccess: (data: IuserLoginReturn) => {
            localStorage.setItem("token", data.token)

            toast({
                position: 'top',
                title: "로그인 성공! ",
                description: "방문을 환영합니다. ",
                status: "success",
                isClosable: true,
            });


            setIsLogin(true)
            reset();
            onClose();
        },
        onError: (data: IseverDetailReturn) => {
            setserverdetailmessage(data.response.data.detail)
        }
    })

    //테마 관련코드
    const btnBgColor = useColorModeValue(lightTheme_colors['btnBgColor'], darkTheme_colors['btnBgColor']);
    const deactivationColor = useColorModeValue(lightTheme_colors['deactivationColor'], darkTheme_colors['deactivationColor']);

    return (
        <Modal isOpen={isOpen} onClose={onClose} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>유저 로그인</ModalHeader>
                <ModalCloseButton />
                <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={"4"}>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<FaUserAlt color='gray.300' />}
                            />
                            <Input
                                {...register("username", { required: "아이디가 입력되지 않았습니다.", })}
                                type='text'
                                placeholder='아이디를 입력하세요'
                            />
                        </InputGroup>
                        {formState.errors.username ?
                            <Center textColor={deactivationColor}>{formState.errors.username?.message}</Center> : ""
                        }
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<FaLock color='gray.300' />}
                            />
                            <Input
                                {...register("password", { required: "패스워드가 입력되지 않았습니다.", })}
                                type={passwordshow ? 'text' : "password"}
                                placeholder='패스워드를 입력하세요'
                            />
                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' onClick={passwordshowHandleClick}>
                                    {passwordshow ? '숨기기' : '보이기'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        {formState.errors.password ?
                            <Center textColor={deactivationColor}>{formState.errors.password?.message}</Center> : ""
                        }
                        {mutation.isError ?
                            <Center textColor={deactivationColor}>{serverdetailmessage}</Center> : ""
                        }
                        <Button isLoading={mutation.isLoading} type="submit" bgColor={btnBgColor} w="100%" >
                            로그인
                        </Button>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}