import React, { useState, useEffect } from 'react';
import axios from 'axios';

// const API_URL = process.env.REACT_APP_API_URL

const CSRFToken = () => {
    const [csrftoken, setcsrftoken] = useState('');

    const getCookie = (name) => {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            // console.log(document.cookie)
            let cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {             
              
                // await axios.get("http://192.168.0.15:8000/j_inforace_api/v1/common/csrf");
                // await axios.get("http://192.168.0.11:8000/j_inforace_api/v1/common/csrf");


                // await axios.get("http://jraceinfo.shop:8079/j_inforace_api/v1/common/csrf");
                await axios.get("https://jraceinfo.shop:8079/j_inforace_api/v1/common/csrf");

            } catch (err) {

            }
        };

        fetchData();
        setcsrftoken(getCookie('csrftoken'));
    }, []);

    return (
        <input type='hidden' name='csrfmiddlewaretoken' value={csrftoken || ""} />
    );
};

export default CSRFToken;
