import { axiosInstance } from "./base";
import Cookies from "js-cookie";

//userCoins
export interface ICoin {
    user: string,
    change_coin: string,
    reason: string,
    buied_day: string,
    buied_race: string,
    created_at: string,
    updated_at: string,
}

export interface IUserCoins {
    "coins_data": ICoin[],
    "total_coin": number,
}
export interface IPostUserCoinFormdata {
    change_coin: number,
    reason: string,
    buied_race?: number,
    buied_day?: string,
}
export const getUserCoins = () => (
    axiosInstance.get(
        `coins/`,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)

export const postUserCoin = (formData: IPostUserCoinFormdata) => (
    axiosInstance.post(
        `coins/`,
        formData,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
                "X-CSRFToken": Cookies.get("csrftoken") || "",
            },
        }
    ).then((response) => response.data)
)

//depasitCoin
export interface IUserDeposits {
    pk: number,
    user: {
        username: string,
        authority: string,
    }
    name: string,
    amount: number,
    situation: string,
    created_at: string,
    updated_at: string,
}
export interface IDepositsprops {
    name: string,
    amount: number,
    situation: string,
}

export const getDepasits = () => (
    axiosInstance.get(
        `deposit/`,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
                "X-CSRFToken": Cookies.get("csrftoken") || "",
            },
        }
    ).then((response) => response.data)
)

export const postDepasitCoin = (formData: IDepositsprops) => (
    axiosInstance.post(
        `deposit/`,
        formData,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
                "X-CSRFToken": Cookies.get("csrftoken") || "",
            },
        }
    ).then((response) => response.data)
)