import { atom } from "recoil";
import { IUserInfo } from "../api/user";
import { IUserCoins } from "../api/coin";

//login
export const IsLoginAtom = atom<Boolean>({
    key: "IsLoginAtom",
    default: false,
})

//User Info
export const UserInfoDefaultData: IUserInfo = {
    id: 0,
    username: "",
    authority: "",
    distributor: {
        pk: 0,
        name: "",
        commission: 0,
    },
    reload: "",
    login_verified: false,
    login_ip: "",
    login_datetime: "",
    logout_datetime: "",
    created_at: "",
}

export const UserInfoAtom = atom<IUserInfo>({
    key: "UserInfoatom",
    default: UserInfoDefaultData,
})


//User Coins
export const UserCoinsAtom = atom<IUserCoins>({
    key: "UserCoinsatom",
    default: {
        "coins_data": [],
        "total_coin": 0,
    }
})