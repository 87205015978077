import { Circle, Flex, Grid, GridItem, Text, useColorModeValue } from "@chakra-ui/react";
import { getHorseGradientStr, getHorseTextColor } from "../../lib/utils";
import { darkTheme_colors, lightTheme_colors } from "../../theme";
export default function InfoTopList({ TopListdata }: { TopListdata: any }) {
    //[{b_ranking_str: '[9, 8, 10, 4, 2, 1, 3, 11, 6, 7]', created_at: '2023-06-08T11:48:38.881943'}....

    const NUMBER_COUNT = 6

    // TopListdata.map((data: any) => {
    //     const b_list = data.b_ranking_str.replace(" ", "").replace("[", "").replace("]", "").split(",")
    //     //['9', '8', ' 10', ' 4', ' 2', ' 1', ' 3', ' 11', ' 6', ' 7']
    //     // 리스트 안의 숫자들을 숫자로 바꾸기
    //     const b_list_number = b_list.map((data: any) => {
    //         return parseInt(data)
    //     })
    //     //[9, 8, 10, 4, 2, 1, 3, 11, 6, 7] 'object'
    //     // b_ranging_list 를 추가한다.
    //     data.b_ranking_list = b_list_number
    // })

    if (TopListdata) {
        TopListdata.forEach((data: any) => {
            data.b_ranking_list = data.b_ranking_str.replace(/\[|\]/g, '').split(',').map(Number);
        });
    }

    //테마 관련코드
    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const mainBgColor = useColorModeValue(lightTheme_colors['mainBgColor'], darkTheme_colors['mainBgColor']);
    const receSelectColor = useColorModeValue(lightTheme_colors['receSelectColor'], darkTheme_colors['receSelectColor']);

    return (
        <>
            {TopListdata === undefined && (
                <Flex h={"100%"} justifyContent={"center"} alignItems={"center"}>
                    <Text>로딩중입니다.</Text>
                </Flex>
            )}

            {TopListdata && (
                <Flex h={"100%"} flexDirection={"column"} justifyContent={"flex-start"} alignItems={"center"} overflow={'hidden'} bg={mainBgColor}>
                    {
                        TopListdata.map((data: any, index: number) => (
                            <Grid
                                key={index}
                                w={'98%'}
                                h={index === 0 ? ('18%') : ('15%')}
                                bg={index === 0 ? (receSelectColor) : (bgColor)}
                                templateRows='1'
                                templateColumns='repeat(10, 1fr)'
                                margin={'0.2rem'}
                                marginY={index === 0 ? ('1rem') : ('0.2rem')}
                                borderRadius={'0.4rem'}
                            >
                                <GridItem
                                    rowSpan={1}
                                    colSpan={8}
                                    overflow={'hidden'}
                                >
                                    <Flex
                                        w={'100%'}
                                        h={'100%'}
                                        justifyContent={"space-around"}
                                        alignItems={"center"}
                                        margin={"0.1rem"}
                                        borderRadius={"0.3rem"}
                                        key={index}
                                    >
                                        {data.b_ranking_list.map((data: any, index: number) => (
                                            index < NUMBER_COUNT && (
                                                <Circle key={index} size={"2rem"} color={getHorseTextColor(data)}
                                                    bgGradient={getHorseGradientStr(data)}
                                                    border={"1px solid black"}
                                                >
                                                    {data}
                                                </Circle>
                                            )
                                        ))}
                                    </Flex>
                                </GridItem>
                                <GridItem
                                    rowSpan={1}
                                    colSpan={2}
                                    overflow={'hidden'}
                                >
                                    <Flex h={'100%'} w={'100%'} justifyContent={"center"} alignItems={'center'} >

                                        <Flex w={'80%'} h={'80%'} justifyContent={'center'} alignItems={'center'} bgColor={mainBgColor} borderRadius={'0.4rem'}>
                                            {data.created_at.split("T")[1].split(".")[0]}
                                        </Flex>
                                    </Flex>
                                </GridItem>
                            </Grid>
                        ))
                    }

                </Flex>
            )}
        </>

    )
}

