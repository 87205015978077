import { axiosInstance } from "./base";
import { QueryFunctionContext } from "@tanstack/react-query";

//racelist
export interface IRacelist {
    pk: number;
    nationality: string;
    region: string;
    number: number;
    hourses: number;
    departure_datetime: string;
    result: string;
    situation: string;
    youtube_url: string;
    is_paied?: Boolean;
}

export const getRaceLists = () => (
    axiosInstance.get(
        `racelist/`,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)

export const getRaceDetail = ({ queryKey }: QueryFunctionContext) => {
    const [_, roomPk] = queryKey;
    return axiosInstance.get(
        `racelist/${roomPk}`,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
}
