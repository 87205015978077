import YouTube from "react-youtube"
import { Center, Flex } from "@chakra-ui/react"

export default function ShowViedo({ youtubeId }: { youtubeId: string }) {

    return (
        <Flex h={"100%"} justifyContent={"space-around"} alignItems={"center"}
        >
            <Center w={"100%"} h={"100%"} margin={"-48"}>
                <YouTube
                    style={{ width: "100%", height: "100%" }}

                    videoId={youtubeId}
                    opts={{
                        width: "100%",
                        height: "100%",
                        playerVars: {
                            autoplay: 1, //자동재생 O
                            rel: 0, //관련 동영상 표시하지 않음 (근데 별로 쓸모 없는듯..)
                            modestbranding: 1, // 컨트롤 바에 youtube 로고를 표시하지 않음
                        },
                    }}
                />
            </Center>
        </Flex>
    )
}