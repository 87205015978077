import { axiosInstance } from "./base";
import Cookies from "js-cookie";



// login
export interface IUserLogin {
    username: string;
    password: string;
}

export interface IuserLoginReturn {
    expiry: Date;
    token: string;
    user: {
        username: string
    }
}

export const userLogIn = ({ username, password }: IUserLogin) =>
    axiosInstance.post(
        `users/login/`,
        { username, password },
        {
            headers: {
                "X-CSRFToken": Cookies.get("csrftoken") || "",
            },
        }
    ).then((response) => response.data);


// logout
export const userLogout = () => (
    axiosInstance.post(
        `users/logout/`,
        null,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)

// signup
export interface ISignup {
    username: string;
    password: string;
    re_password: string;
    distributor: number;
}

export const useSignup = (formData: ISignup) =>
    axiosInstance.post(
        `users/signup/`,
        formData,
        {
            headers: {
                "X-CSRFToken": Cookies.get("csrftoken") || "",
            },
        }
    ).then((response) => response.data);

// user info 
export interface IUserInfo {
    id: number;
    username: string;
    authority: string;
    distributor: {
        pk: number;
        name: string;
        commission: number;
    }
    reload: string;
    login_verified: boolean;
    login_ip: string;
    login_datetime: string;
    logout_datetime: string;
    created_at: string;
}

export const getUserInfo = () => (
    axiosInstance.get(
        `users/user/`,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)


//userReLoadDone
export const putUserReLoadDone = () => (
    axiosInstance.put(
        `users/user/`,
        {
            "reload": "done"
        },
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)



