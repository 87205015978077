import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { UserInfoAtom } from "../atoms/user";

export default function useLoginOnlyPage() {
    const userinfo = useRecoilValue(UserInfoAtom)
    const navigate = useNavigate();
    useEffect(() => {
        if (userinfo.username === "") {
            navigate("/loginfirst");
        }
    }, [userinfo]);
}