import { Button, Flex, HStack, Text, useColorModeValue, } from "@chakra-ui/react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from "recoil";
import { IsLoginAtom, UserCoinsAtom } from "../atoms/user";
import { IsRaceInfoPageAtom, DoingRaceAtom, raceInfoPageInfoDataIs12Atom, raceinfoIsPauseAtom } from "../atoms/race";
import { IUserCoins } from "../api/coin";
import { IRacelist } from "../api/racelist";
import { darkTheme_colors, lightTheme_colors } from "../theme";

export default function Footer() {
    const navigate = useNavigate();
    const location = useLocation();
    const isLogin = useRecoilValue<Boolean>(IsLoginAtom);
    const userCoins = useRecoilValue<IUserCoins>(UserCoinsAtom);
    const doingRace = useRecoilValue<IRacelist>(DoingRaceAtom)

    // raceInfoPageControl 
    const [IsbaseInfo, setIsbaseInfo] = useRecoilState(IsRaceInfoPageAtom)
    const [raceInfoPageInfoDataIs12, setRaceInfoPageInfoDataIs12] = useRecoilState(raceInfoPageInfoDataIs12Atom)
    const [raceInfoIsPause, setRaceInfoIsPause] = useRecoilState(raceinfoIsPauseAtom)

    // 현제페이지가 리스트 페이지인지 확인(로그인후 루트가 리스트페이지임을 이용)
    const [isRaceListPage, setIsRaceListPage] = useState<boolean>(false)
    useEffect(() => {
        location.pathname === "/" ? setIsRaceListPage(true) : setIsRaceListPage(false)
    }, [location])

    const onInfoChangeClick = () => {
        setIsbaseInfo(!IsbaseInfo)
    }
    const onInfoDataIs12 = () => {
        setRaceInfoPageInfoDataIs12(!raceInfoPageInfoDataIs12)
    }
    const onRaceInfoIsPause = () => {
        setRaceInfoIsPause(!raceInfoIsPause)
    }

    // const [ userCoins, setUserCoins ] = useRecoilState(UserCoinsAtom);
    const onclickhome = () => {
        navigate("/")
    }
    const onclickRaceInfo = () => {
        navigate("raceinfo/" + doingRace.pk)
    }

    const onclickbuyRaceInfo = () => {
        navigate("buyrace/" + doingRace.pk)
    }

    //테마 관련코드
    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const btnBgColor = useColorModeValue(lightTheme_colors['btnBgColor'], darkTheme_colors['btnBgColor']);
    const btnSelectColor = useColorModeValue(lightTheme_colors['btnSelectColor'], darkTheme_colors['btnSelectColor']);

    return (
        !isLogin ? (
            <Flex height={"100%"} justifyContent={"space-between"} bgColor={bgColor}>
                <Flex margin={"0.5rem"} flex={"1"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} overflow={"hidden"}>
                    <Text fontSize={"0.1rem"}> - 이용약관 | 개인정보 취급방침 | 사업자정보 -</Text>
                    <Text fontSize={"0.1rem"}>(주) soojung-soft</Text>
                </Flex>

                <Flex margin={"0.5rem"} flex={"1"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} overflow={"hidden"}>
                    <Text fontSize={"0.5rem"}> 예상 정보이며 정보제공만을 취급합니다. </Text>
                    <Text fontSize={"0.5rem"}> 결과에 대해 책임지지 않습니다.</Text>
                    <Text fontSize={"0.5rem"}> 배팅에대한 문의는 받지 않습니다.</Text>
                </Flex>
            </Flex>
        ) : (
            isRaceListPage ? (
                <Flex height={"100%"} justifyContent={"space-between"} bgColor={bgColor}>
                    <Flex margin={"0.2rem"} flex={"2"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} overflow={"hidden"} bg={btnBgColor} borderRadius={"xl"}>
                        <HStack fontSize={"xl"}>
                            <Text> 코인 :</Text>
                            <Text> {userCoins.total_coin} </Text>
                        </HStack>
                        <Link to="depasitcoin">
                            <Text> 구매신청 </Text>
                        </Link>
                    </Flex>

                    <Flex margin={"0.2rem"} flex={"1"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} overflow={"hidden"} >
                        {
                            doingRace.is_paied ? (
                                <Button onClick={onclickRaceInfo} margin={"0.1rem"} bgColor={btnBgColor} fontSize={"small"}>진행경주 보기</Button>
                            ) : (
                                <Button onClick={onclickbuyRaceInfo} margin={"0.1rem"} bgColor={btnBgColor} fontSize={"small"}>진행경주보기</Button>
                            )
                        }
                    </Flex>
                </Flex>
            ) : (

                <Flex height={"100%"} justifyContent={"space-between"} bgColor={bgColor}>
                    <Flex margin={"0.2rem"} flex={"1"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} overflow={"hidden"}>
                        {
                            raceInfoPageInfoDataIs12 ? (
                                <Button onClick={onInfoDataIs12} marginBottom={"0.3rem"} fontSize={"small"} bgColor={btnBgColor}>InfoLine 변경</Button>
                            ) : (
                                <Button onClick={onInfoDataIs12} marginBottom={"0.3rem"} fontSize={"small"} bgColor={btnSelectColor}>InfoLine 변경</Button>
                            )
                        }
                        {
                            IsbaseInfo ? (
                                <Button onClick={onInfoChangeClick} fontSize={"small"} bgColor={btnBgColor}>기본정보</Button>
                            ) : (
                                <Button onClick={onInfoChangeClick} fontSize={"small"} bgColor={btnSelectColor}>목록정보</Button>
                            )
                        }
                    </Flex>
                    <Flex margin={"0.2rem"} flex={"1"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} overflow={"hidden"}>
                        {/* <Text marginBottom={"0.1rem"}>  
                            {raceInfoPageInfoDataIs12?"Line : 보통":"Line : 작게"}
                        </Text> */}
                        {
                            raceInfoIsPause ? (
                                <Button onClick={onRaceInfoIsPause} margin={"0.1rem"} bgColor={btnSelectColor} fontSize={"small"}>일시정지 해제!!</Button>
                            ) : (
                                <Button onClick={onRaceInfoIsPause} margin={"0.1rem"} bgColor={btnBgColor} fontSize={"small"}>일시 정지!!</Button>
                            )
                        }
                    </Flex>




                    <Flex margin={"0.2rem"} flex={"1"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} overflow={"hidden"} >
                        <Button onClick={onclickhome} margin={"0.1rem"} bgColor={btnBgColor} fontSize={"small"}>홈 화면으로 </Button>
                    </Flex>
                </Flex>
            )
        )
    )
}