import { axiosInstance } from "./base";


// Distributors

export interface ITinyDistributorsReturn {
    pk: number;
    name: string;
}

export const getTinyDistributors = () => (
    axiosInstance.get(
        `distributors/tiny`
    ).then((response) => response.data)
)