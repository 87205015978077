import { atom } from "recoil";
import { IRacelist } from "../api/racelist";


// DoingRace
export const DoingRaceAtom = atom<IRacelist>({
    key: "DoingRaceAtom",
    default: {
        pk: 0,
        nationality: "",
        region: "",
        number: 0,
        hourses: 0,
        departure_datetime: "",
        result: "",
        situation: "",
        youtube_url: "",
        is_paied: false,
    }
})


// raceInfoPageControl
export const IsRaceInfoPageAtom = atom({
    key: "BaseInfoTagAtom",
    default: true,

})
export const raceInfoPageInfoDataIs12Atom = atom({
    key: "raceInfoPageInfoDataIs12Atom",
    default: true,
})
export const raceinfoIsPauseAtom = atom({
    key: "raceinfoIsPauseAtom",
    default: false,
})