import { Flex, Square } from "@chakra-ui/react";
import { getRegin } from "../../lib/utils";
import 'moment/locale/ko';
import { useState } from 'react';
import useInterval from "../../actions/customHook";
import { IRacelist } from "../../api/racelist";

interface IRaceTimeprop {
    racedata: IRacelist | undefined;
}


export default function RaceTime({ racedata }: IRaceTimeprop) {
    const [timestr, settimestr] = useState<string>()


    useInterval(() => {
        if (racedata) {
            const nowTime = new Date()
            const data = (racedata as any) as IRacelist
            const racetime = new Date(data.departure_datetime)

            const Dday = racetime.getTime() - nowTime.getTime();
            const hours = Math.floor((Dday % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            const Minutes = Math.floor((Dday % (1000 * 60 * 60)) / (1000 * 60))
            const Seconds = Math.floor((Dday % (1000 * 60)) / 1000)

            if (Dday < 0) {
                if (racedata.result === null) {
                    settimestr(`마감 `)
                } else if (racedata.result === '0') {
                    settimestr(`결과 입력중`)
                } else {
                    settimestr(`결과 : ${racedata.result}`)
                }
            } else {
                if (hours > 0) {
                    settimestr(`${hours}시간
                     ${Minutes}분 ${Seconds}초전`)
                } else {
                    settimestr(`${Minutes}분 ${Seconds}초전`)
                }
            }
        }
    }, 1000);

    return (
        <Flex h={"100%"} justifyContent={"space-around"} alignItems={"center"} >
            {racedata === undefined ? (
                <Square fontSize={"2xl"}>진행중인 경기가 없습니다.</Square>
            ) : (
                <>
                    <Square fontSize={"2xl"}>{getRegin(racedata.region)} {racedata.number} 경주 </Square>
                    <Square fontSize={"2xl"}>{timestr}</Square>
                </>
            )}
        </Flex>
    )

}