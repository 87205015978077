import { Button, Center, Input, InputGroup, InputLeftAddon, InputLeftElement, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Stack, useColorModeValue, useToast } from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ISignup, useSignup } from "../api/user";
import { ITinyDistributorsReturn, getTinyDistributors } from "../api/distributor";
import { IseverDetailReturn } from "../api/base";
import { darkTheme_colors, lightTheme_colors } from "../theme";

interface SignupModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export default function SignupModal({ isOpen, onClose }: SignupModalProps) {
    const toast = useToast();
    const { register, handleSubmit, formState, reset } = useForm<ISignup>();
    const [passwordshow, setPasswordshow] = useState(false)
    const passwordshowHandleClick = () => setPasswordshow(!passwordshow)
    const [repasswordshow, setrePasswordshow] = useState(false)
    const repasswordshowHandleClick = () => setrePasswordshow(!repasswordshow)
    const [serverdetailmessage, setserverdetailmessage] = useState("")

    const { data: DistibuterList } = useQuery<ITinyDistributorsReturn[]>(['tinyDistributors'], getTinyDistributors)
    const onSubmit = (singpuformdata: ISignup) => {
        mutation.mutate(singpuformdata)
    }

    const mutation = useMutation(useSignup, {
        onSuccess: (data: IseverDetailReturn) => {

            toast({
                position: 'top',
                title: "가입성공! ",
                description: "가입을 환영합니다. 로그인해 주세요 ",
                status: "success",
                isClosable: true,
            });

            reset();
            onClose();
        },
        onError: (data: IseverDetailReturn) => {
            setserverdetailmessage(data.response.data.detail)
        }
    })

    //테마 관련코드
    const btnBgColor = useColorModeValue(lightTheme_colors['btnBgColor'], darkTheme_colors['btnBgColor']);
    const deactivationColor = useColorModeValue(lightTheme_colors['deactivationColor'], darkTheme_colors['deactivationColor']);
    return (
        <Modal isOpen={isOpen} onClose={onClose} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>유저 가입</ModalHeader>
                <ModalCloseButton />
                <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={"4"}>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<FaUserAlt color='gray.300' />}
                            />
                            <Input
                                {...register("username", { required: "아이디가 입력되지 않았습니다.", })}
                                type='text'
                                placeholder='아이디를 입력하세요'
                            />
                        </InputGroup>
                        {formState.errors.username ?
                            <Center textColor={deactivationColor}>{formState.errors.username?.message}</Center> : ""
                        }
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<FaLock color='gray.300' />}
                            />
                            <Input
                                {...register("password", { required: "패스워드가 입력되지 않았습니다.", })}
                                type={passwordshow ? 'text' : "password"}
                                placeholder='패스워드를 입력하세요'
                            />
                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' onClick={passwordshowHandleClick}>
                                    {passwordshow ? '숨기기' : '보이기'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        {formState.errors.password ?
                            <Center textColor={deactivationColor}>{formState.errors.password?.message}</Center> : ""
                        }

                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<FaLock color='gray.300' />}
                            />
                            <Input
                                {...register("re_password", { required: "패스워드 확인이 입력되지 않았습니다.", })}
                                type={repasswordshow ? 'text' : "password"}
                                placeholder='패스워드를 다시 입력하세요'
                            />
                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' onClick={repasswordshowHandleClick}>
                                    {repasswordshow ? '숨기기' : '보이기'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        {formState.errors.re_password ?
                            <Center textColor={deactivationColor}>{formState.errors.re_password?.message}</Center> : ""
                        }
                        <InputGroup>
                            <InputLeftAddon children='총판' />
                            <Select
                                {...register("distributor", { required: true })}
                                defaultValue="1"
                                placeholder="총판을 선택하세요"
                            >
                                {DistibuterList?.map((distributor) => (
                                    <option key={distributor.pk} value={distributor.pk}  >
                                        {distributor.name}
                                    </option>
                                ))}
                            </Select>
                        </InputGroup>

                        {mutation.isError ?
                            <Center textColor={deactivationColor}>{serverdetailmessage}</Center> : ""
                        }
                        <Button isLoading={mutation.isLoading} type="submit" bgColor={btnBgColor} w="100%" >
                            회원가입
                        </Button>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}