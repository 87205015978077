import axios from "axios";

const BASE_URL = "https://jraceinfo.shop:8079/j_inforace_api/v1/";
// const BASE_URL = "http://127.0.0.1:8000/kra_autobuy_api/v1";
// const BASE_URL = "http://192.168.0.11:8000/j_inforace_api/v1/";
// const BASE_URL = "http://192.168.0.22:8000/kra_autobuy_api/v1";





export const axiosInstance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
});



export interface IseverDetailReturn {
    response: {
        data: {
            detail: string;
        }
    }
}

//csrf token
export function getCSRFtoken() {
    return axiosInstance.get(`/common/csrf/`).then(function (response) {
        // console.log(response.data);
    })
}
